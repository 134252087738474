<template>
  <v-container class="fill-height justify-center">
    <v-container>
      <v-row justify="center">
        <v-img max-height="150" max-width="300" src="../assets/LicenseServer_TitleText_white.png"></v-img>
      </v-row>
      <div v-if="linkExpired">
        <v-row justify="center">
          <mex-card color="primary" icon="mdi-key" light max-width="100%" title="Reset Password" width="400">
            <v-row justify="center">
              <mex-p content="This link is expired!"/>
            </v-row>
          </mex-card>
        </v-row>
      </div>
      <div v-else>
        <v-row justify="center">
          <v-expand-transition>
            <validation-observer v-slot="{ handleSubmit }">
              <v-form @submit.prevent="handleSubmit(validatePasswordReset)">
                <mex-card v-if="!passwordReset" color="primary" icon="mdi-key" light max-width="100%" title="Reset Password" width="400">
                  <v-row justify="center">
                    <v-col cols="12">
                      <validation-provider v-slot="{ errors }" name="new password" rules="required|password">
                        <v-text-field
                          prepend-icon="mdi-form-textbox-password"
                          label="Password"
                          type="password"
                          v-model="newPassword"
                          :error-messages="errors"
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>
                  <v-row justify="center">
                    <v-col cols="12">
                      <validation-provider v-slot="{ errors }" name="new password confirmation" rules="required|passwordConfirm:@new password">
                        <v-text-field
                          prepend-icon="mdi-form-textbox-password"
                          label="Repeat password"
                          type="password"
                          v-model="repeatPassword"
                          :error-messages="errors"
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>
                  <v-row justify="center">
                    <mex-btn class="ma-5" content="Reset Password" type="submit"/>
                  </v-row>
                </mex-card>
                <mex-card v-else color="primary" icon="mdi-key" light max-width="100%" title="Reset Password" width="400">
                  <v-row justify="center">
                    <mex-p content="You can now login with your new password"/>
                  </v-row>
                  <v-row>
                    <v-spacer></v-spacer>
                    <mex-btn class="ma-5" content="Go to Login" @click="goToLogin"/>
                    <v-spacer></v-spacer>
                  </v-row>
                </mex-card>
              </v-form>
            </validation-observer>
          </v-expand-transition>
        </v-row>
      </div>
    </v-container>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import SystemUsersService from '../services/systemUsers.service';

export default {
  name: "ResetPassword.vue",
  computed: {
    ...mapGetters('theme', ['getDarkModeSetting','getActTheme']),
  },
  data() {
    return {
      SystemUserId: null,
      timestamp: null,
      passwordReset: false,

      linkExpired: false,
      newPassword: '',
      repeatPassword: '',
    };
  },
  created() {
    this.$store.commit('sysAuth/logout');
    SystemUsersService.systemUserLogout();
    this.$vuetify.theme.themes = this.getActTheme;
    this.$vuetify.theme.dark = this.getDarkModeSetting;
    this.decodeQueryParams();
    this.SystemUserID = this.$route.query.SystemUserID;
  },
  methods: {
    decodeQueryParams() {
      Object.keys(this.$route.query).forEach((key) => {
        let data = key;
        let buff = Buffer.from(data, 'base64');
        const decodedKey = buff.toString('ascii');
        data = this.$route.query[key];
        buff = Buffer.from(data, 'base64');
        this.$route.query[decodedKey] = buff.toString('ascii');
      });
    },
    validatePasswordReset() {
      this.resetPassword();
    },
    resetPassword() {
      SystemUsersService.resetPassword(this.SystemUserID, this.newPassword)
        .then(() => {
          this.$toast.success('Password reset');
          this.passwordReset = true;
        })
        .catch((err) => {
            if (err.response.status === 403) {
              this.linkExpired = true;
            } else {
              this.$toast.error('Reseting password failes. Please try again later');
            }
        })
    },
    goToLogin() {
      this.$router.push('/');
    }
  },
};
</script>

<style scoped>

</style>
